import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'

export default class SystemTemplate extends Model {
    endpoint = 'system-templates';

    related = [];

    fields = [
      new Field('id', 'Código').disabled().noFilterable(),
      new Field('name', 'Nombre'),
      new Field('description', 'Descripción'),
    ]

    clone = () => SystemTemplate;
}
